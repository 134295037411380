import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import utah from '../Assets/williamsonFamily.jpeg';
import spanishPic from '../Assets/spanish-pic.jpg';
import wansutter from '../Assets/wansutter.png';
import './Homepage.css';
import Joyride, { Step } from 'react-joyride';
import heart from '../Assets/flourHeart.jpg';

type Props = {}

interface State {
    run: boolean;
    steps: Array<{ target: string; content: string; disableBeacon?: boolean }>;
    showModal: boolean;
    dontShowAgain: boolean;
}

const sponsors = [
    { name: 'Wansutter HR Consulting', image: wansutter }
];

class Homepage extends Component<Props, State> {
    state: State = {
        run: false,
        steps: [
            {
                target: '.discover-section-title',
                content: 'Welcome to our website! Start your journey by a quick tour.',
                disableBeacon: true
            },
            {
                target: '.sponsor-section',
                content: 'See our sponsors who help make this website possible!',
            },
            {
                target: '.begin-section-button-container',
                content: 'Click to view providers, take M-CHAT or CAST, or contact us for any questions.',
            },
            {
                target: '.what-we-are-about',
                content: 'Learn more about what we are all about!',
            },
            {
                target: '#menu-button',
                content: 'Click menu button to view the contents and explore our website!',
            },
        ],
        showModal: false,
        dontShowAgain: false
    }

    componentDidMount() {
        const lastVisit = localStorage.getItem('lastVisit');
        const dontShow = localStorage.getItem('dontShowAgain');

        const now = new Date().getTime();
        const twentyFourHours = 24 * 60 * 60 * 1000;

        if (!dontShow && (!lastVisit || now - parseInt(lastVisit) > twentyFourHours)) {
            this.setState({ showModal: true });
        }
    }

    handleCheckboxChange = () => {
        this.setState(prevState => ({
            dontShowAgain: !prevState.dontShowAgain
        }));
    }

    closeModal = () => {
        const { dontShowAgain } = this.state;

        localStorage.setItem('lastVisit', new Date().getTime().toString());

        if (dontShowAgain) {
            localStorage.setItem('dontShowAgain', 'true');
        }

        this.setState({ showModal: false });
    }

    handleJoyrideCallback = (data: any) => {
        const { status } = data;
        const finishedStatuses = ['finished', 'skipped'];

        if (finishedStatuses.includes(status)) {
            localStorage.setItem('hasVisited', 'true');
        }
    }

    render() {
        const { run, steps, showModal, dontShowAgain } = this.state;

        // console.log('is modal showing', showModal);

        return (
            <div className="homepage-container">
                {/* Modal */}
                {showModal && (
                    <div className="homepage-modal-overlay">
                        <div className="homepage-modal">
                            <h2>Recent Changes!</h2>
                            <div className="homepage-modal-content">
                                <p>If you have been to our website before, you may have noticed some changes! 
                                We have changed our name from Utah ABA Locator to Autism Services Locator and we are now a 501(c)(3) organization.</p>

                                <ul>
                                    <li><strong>Sponsorship Opportunities:</strong> Become a sponsor and support our mission! Sponsors will be featured in a special <strong>Sponsors Section</strong> on our site recognizing their contributions to the autism care community.</li>
                                    <br />
                                    <li><strong>Donation Feature:</strong> You'll be able to donate to our organization to help support our mission and help support families in need.</li>
                                    <br />
                                    <li><strong>Expanding Services:</strong> We're currently adding <strong>Occupational</strong> and <strong>Speech Therapy</strong> , and <strong>Autism Evaluation Providers</strong> to our platform, making it easier for you to find comprehensive care options for your child all in one place.</li>
                                    <br />
                                </ul>
                                    <h2 className='text-center'>Upcoming Changes!</h2>
                                    <ul>
                                    <li><strong>Nationwide Coverage:</strong> Our website is expanding to cover the entire <strong>United States</strong>! No matter where you are, you'll soon be able to find the right ABA providers, therapists, and resources near you.</li>
                                    </ul>
                            </div>
                            <div>
                                <label>
                                    <input
                                        type="checkbox"
                                        onChange={this.handleCheckboxChange}
                                        checked={dontShowAgain}
                                    />
                                    Don't show this again
                                </label>
                            </div>

                            <button className="homepage-modal-button" onClick={this.closeModal}>Go to Homepage</button>
                        </div>
                    </div>
                )}

                <Joyride
                    run={run}
                    steps={steps}
                    continuous={true}
                    showProgress={true}
                    showSkipButton={true}
                    callback={this.handleJoyrideCallback}
                />

                {/* Discover Section */}
                <div className="discover-section">
                    <img src={utah} alt="background-image" className="discover-section-backgroundImage" />
                    <div className="discover-section-container">
                        <h1 className="discover-section-title">Find The Best Providers For Your Child</h1>
                        <div className="discover-section-content">
                            <p className="discover-section-description">
                                We’re here to guide you every step of the way in finding the best providers for you and your family. Start your journey with a bit of ease.
                            </p>
                        </div>
                        <div className="discover-section-button-container">
                            <Link to="/providers" className="discover-section-button">Start Your Journey</Link>
                        </div>
                    </div>
                </div>

                {/* Counties Section */}
                <div className="sponsor-section">
                    <div className="sponsor-section-title-container">
                        <h2 className="sponsor-section-title">Our <br/> Proud <br/> Sponsors</h2>
                    </div>
                    <div className="sponsor-section-list-wrapper">
                        {sponsors.map((sponsor, index) => (
                            <div className="sponsor-section-list" key={index}>
                                <img src={sponsor.image} alt={sponsor.name} />
                            </div>
                        ))}
                    </div>
                </div>

                {/* Begin Section */}
                <div className="begin-section">
                    <h2 className="begin-section-title">Begin Your Journey with Essential Information</h2>
                    <p className="begin-section-description">
                        Not sure about your child's condition or haven't received a diagnosis yet? <br />
                        Start by taking a simple screening test to see if your child or yourself meet the criteria.
                    </p>
                    <div className="begin-section-button-container">
                        <Link to="/providers" className="begin-section-button1"> VIEW PROVIDERS</Link>
                        <Link to="/screening" className="begin-section-button2">SCREENING TOOLS</Link>
                        <Link to="/contact" className="begin-section-button3"> CONTACT US</Link>
                    </div>
                </div>

                {/* Icons Section */}
                <div className="what-we-are-about" id="view">
                    <div className="what-we-are-about-container">
                        <img src={heart} alt="heart" className="what-we-are-about-image" />
                    </div>
                    <div className="what-we-are-about-text-container">
                        <h1 className="what-we-are-about-title">What We're All About</h1>
                        <h2 className="what-we-are-about-subtitle">Made for Everyone</h2>
                        <p className="what-we-are-about-description">We founded Autism Services with one goal in mind: to provide high-quality support and resources for families seeking different types of providers. Our mission is to ensure that families have access to the best possible care for their children, regardless of their location. We strive to make the process of finding and connecting with autism services simple, reliable, and accessible for everyone.</p>
                        <button className="max-w-fit bg-[#4A6FA5] text-white rounded-md px-4 py-2 hover:bg-[#A54A4A] border-none">Learn More About Us</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Homepage;
